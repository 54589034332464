import { toCurrency } from "../functions";
import pdfMake from 'pdfmake'
import fonts from "../data/fonts";
const { bg } = require("../images");
var _ = require('lodash')


pdfMake.vfs = fonts;

pdfMake.fonts = {
  Sen: {
    normal: 'Sen-Regular.ttf',
    bold: 'Sen-Bold.ttf',
    italics: 'Sen-Regular.ttf',
    bolditalics: 'Sen-ExtraBold.ttf'
  }
};

export default function generate_history(info, data) {

  var rows = [];
  const total = _.sumBy(data, dt => dt.amount)
  rows.push([
    { text: "Fee Payment History" },
    {},
    {},
    {},
    {},
    { text: "Total: " + toCurrency(total) },
  ]);
  rows.push([
    { text: "Payment ID", bold: true },
    { text: "Fee Type", bold: true },
    { text: "Amount", bold: true },
    { text: "Balance", bold: true },
    { text: "Date Paid", bold: true },
    { text: "Paid By", bold: true },
  ]);
  for (var dt of data) {
    rows.push([
      dt.timeStamp,
      dt.fee_type,
      dt.amount,
      dt.balance,
      new Date(dt.date_paid).toLocaleDateString(),
      dt.paid_by,
    ]);
  }

  console.log(rows);
  const docDefinition = {
    pageOrientation: "potrait",
    paperSize: "A4",
    defaultStyle:{
      font:'Sen', 
      fontSize:11,
    }, 
    pageMargins: [15, 15, 15, 15],
    background: {
      image: bg,
      width: 595,
      absolutePosition: { x: 0, y: 0 },
    },
    content: [
      {
        text: "Payment History For: "  + info.last_name + " " + info.other_names,
        absolutePosition: { x: 15, y: 110 },
      },
      {
        text: "As At: " + info.current_class ,
        absolutePosition: { x: 15, y: 125 },
      },
      {
        text: "Generated at: " + new Date().toLocaleString(),
        absolutePosition: { x: 15, y: 140 },
      },
      {
        absolutePosition: { x: 15, y: 185 },
        
        table: {
          widths: [130, 80, 50, 60, 80, 120],
          body: rows,
        },
      },
    ],
  };

  return pdfMake.createPdf(docDefinition).print();
}
