import { useEffect, useState } from "react";
import { composeMessage } from "../../functions";
import { toCurrency } from "../../functions";
import { motion } from "framer-motion";
import { proxy, readDocuments } from "../../services/calls";
import _ from "lodash";
import { uploadData } from "../../services/calls";
import { Feedback } from "./feedback";
import axios from "axios";

export default function Broadcast({ senderID }) {
  const [records, setRecords] = useState([]);
  const [response, setResponse] = useState({
    open: false,
    message: "",
    type: "",
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    readDocuments({
      path: `/fees/find-records?date=${new Date().toISOString().slice(0, 10)}`,
      getData: setRecords,
    });
  }, []);

  //eslint-disable-next-line
  const sendMessage = async (info) => {
    var message = composeMessage(info);

    const data = {
      message,
      target: [info.target],
      sender_id: senderID,
    };

    try {
      await uploadData({
        path: "/messages/send-sms",
        data,
      });
      setLoading(false);
    } catch (err) {
      setResponse({
        message: err.message,
        open: true,
        type: "error",
      });
    }
  };

  const totalToday = _.sumBy(records, (rc) => rc.amount);

  const sendMessages = async () => {
    const messages = [];
    for (var rec of records) {
      if (
        parseInt(rec.phone) === 0 ||
        parseInt(rec.phone).toString().length < 10 || !rec.phone?.startsWith(0)
      ) {
        continue;
      } else {
        messages.push({
          name: rec.last_name + " " + rec.first_name,
          fee_type: rec.fee_type,
          amount: rec.amount,
          target: rec.phone,
          date: rec.date_paid?.slice(0, 10),
          balance: rec.balance,
          id: rec.payment_id,
          paid_by: rec.paid_by,
        });
      }
    }
    for (var info of messages) {
      await sendMessage(info);
    }
  };

  const alertDebtors = async () => {
    const { smsAPIKey, senderID, dbName } = JSON.parse(localStorage.getItem("school"));
    // console.log(smsAPIKey, senderID)

    await axios.post(`${proxy}/messages/alert-debtors?key=${smsAPIKey}&senderID=${senderID}`, {}, {
      headers: {
        dbName: dbName
      }
    }).then(() => setResponse({ open: true, message: "Messages sent successfully", type: "success" })).catch(err => setResponse({ open: true, message: "Something went wrong sending messages", type: "error", }))
  }

  return (
    <motion.div
      initial={{ x: 100, y: 10 }}
      animate={{ x: 10, y: 0 }}
      transition={{ type: "spring" }}
      exit={{ y: 100, x: 10 }}
      className="fixed top-0 left-0 w-screen  p-10 h-screen bg-white/40 backdrop-blur-sm"
    >
      {response.open && (
        <Feedback setOpen={() => setResponse({ open: false })} {...response} />
      )}
      <div className="flex flex-col absolute bottom-10 p-2 right-10 h-[80vh] rounded-t-md w-96 shadow-md bg-white">
        <div>
          <h3 className="text-lg font-bold">Broadcast Messages</h3>
          <p className="text-sm">
            Preview payments made today and then notify payments through SMS to
            the payments.
            <p className="text-xs py-2">Payment Today</p>
          </p>
        </div>
        <div className="flex flex-col p-2 gap-2 h-[60vh] overflow-y-auto">
          {records?.map((info, idx) => (
            <div
              className="flex p-2 bg-gray-50 justify-between items-center"
              key={idx}
            >
              <p className="text-sm truncate w-[70%]">
                {info.last_name + " " + info?.first_name}
              </p>
              <p className="text-sm">{toCurrency(info?.amount)}</p>
            </div>
          ))}
        </div>
        <div className="flex items-center gap-2 mt-2">
          <button
            onClick={() => alertDebtors()}
            className="p-2 bg-red-500 text-sm text-white w-1/2"
          >
            {"Alert Debtors"}
          </button>

          <div className="bg-blue-500 text-sm text-white w-1/2 p-2">
            Total: {toCurrency(totalToday)}
          </div>
          <button
            onClick={() => sendMessages()}
            className="p-2 bg-orange-500 text-sm text-white w-1/2"
          >
            {"Send Message" || loading}
          </button>
        </div>
      </div>
    </motion.div>
  );
}
